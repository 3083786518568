import React from "react";
import Carousel from "react-multi-carousel";
import { StaticQuery, graphql } from "gatsby";
import { SEO, Layout } from "components";

import styles from './styles.module.scss';

interface Photo {
  frontmatter: {
    title: string;
    image: string;
  }
}

interface PhotoBlock {
  title: string;
  image: string;
}

const Gallery = () => {
  const responsive = {
    default: {
      breakpoint: { min: 1024, max: 10000 },
      items: 1
    },
    mobile: {
      breakpoint: { min: 0, max: 1023 },
      items: 1
    }
  };

  const carouselProps = {
    infinite: true,
    arrows: true,
    responsive: responsive,
    ssr: true,

  };

  return (
    <Layout>
      <SEO title="gallery" />
      <div className={styles.gallery}>
        <div className={styles.container}>
          <StaticQuery
            query={
                graphql`query GalleryQuery {
                  allMarkdownRemark (
                    filter: {
                      fileAbsolutePath: {regex : "\/_gallery/"},
                    }
                  ) {
                    nodes {
                      frontmatter {
                        title
                        image
                      }
                    }
                  }
                }`
              }
              render={data => {
                const photos = data.allMarkdownRemark.nodes.map((photo: Photo) => (
                  {
                    title: photo.frontmatter.title,
                    image: photo.frontmatter.image,
                  }
                ));
                return (
                  <Carousel {...carouselProps}>
                    {photos && photos.map((photo: PhotoBlock) => (
                      <div className={styles.element}>
                        <img src={photo.image} alt={photo.title} loading="lazy"/>
                      </div>
                    ))}
                  </Carousel>
              )}}
            />
        </div>
      </div>
    </Layout>
  );
}

export default Gallery;
